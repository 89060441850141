'use client'

import useTranslateMessage from '@hooks/useTranslateMessage'
import { useAnalytics } from '@lib/contexts/analyticsContext'
import { usePathname } from 'next/navigation'
import { FC, useEffect } from 'react'
import { useLocale } from '@hooks/useLocale'
import { KonvoloChat } from '@lib/utils/konvoloHelpers'
import { Button } from '@hermes/web-components'
import { useNavigation } from '@hooks/useNavigation'
import { ErrorContainer, GlobalErrorProps } from '../containers/Error/Error'

interface ErrorProps {
  statusCode?: number
  message?: string
  error?: Error & { digest?: string }
  reset?: () => void
}

export const ErrorPage: FC<ErrorProps> = ({ statusCode, message, error, reset }) => {
  const analytics = useAnalytics()
  const path = usePathname()
  const { t } = useTranslateMessage()
  const locale = useLocale()
  const navigation = useNavigation()
  useEffect(() => {
    analytics.trackEvent('APPLICATION_ERROR', {
      name: `${message} (/${locale}${path})`,
      message: `${statusCode}, ${error} - ${locale}${path}`
    })
  }, [])

  const localisation: GlobalErrorProps['localisation'] = {
    errorTitle: statusCode === 404 ? t({ id: '404.title' }) : t({ id: 'error.title' }),
    errorDescription: statusCode === 404 ? t({ id: '404.text' }) : t({ id: 'error.generic' }),
    buttonGoHome: t({ id: '404.button.goToHome', defaultMessage: 'Go to homepage' }),
    buttonTryAgain: t({ id: 'tryagain' })
  }

  return (
    <ErrorContainer reset={reset} locale={locale} localisation={localisation}>
      {statusCode === 404 && (
        <Button variant="outlined" onClick={() => navigation.navigate.feedBackPage(KonvoloChat.REPORT_ERROR_WEB, path)}>
          {t({ id: 'give.feedback' })}
        </Button>
      )}
    </ErrorContainer>
  )
}
