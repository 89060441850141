'use client'

import { Providers } from '@components/Providers'
import { Locale } from '@lib/utils/localeHelpers'
import React from 'react'
import { Header } from './Header'
import { ErrorPage } from './LocalisedError'

interface NotFoundProps {
  locale: Locale
  message: string
  translations: Record<string, string>
}

export const NotFound: React.FC<NotFoundProps> = ({ locale, message, translations }) => (
  <Providers translations={translations} locale={locale}>
    <Header localeOverride={locale} />
    <ErrorPage statusCode={404} message={message} />
  </Providers>
)
